<template>
<div class="mt-10 mb-10 h-100vh">

    <v-container v-if="show3">
        <div class="reserve_box relative-center">
            <Flow :Check="check" />
            <div class="text-left">
                <v-btn text color="indigo accent-4" @click="back">＜【1.施工箇所の選択】に戻る</v-btn>
            </div>
            <v-card class="pa-6">
                <v-row>
                    <v-col cols="12" v-if="Info.type==1">
                        <h3 class="f-12 mb-1 f-bgray text-left">
                            <span v-if="this.Station.length>1">施工場所を選択してください</span>
                            <span v-else>施工場所</span>
                        </h3>
                        <v-select :items="Station" item-value="id" item-text="name" label="施工場所" v-model="station" outlined v-if="this.Station.length>1" @change="stationChange()" />
                        <div class="bold f-14 text-left f-orange" v-else>
                            {{Station[Station.findIndex(({id}) => id === station)].name}}
                        </div>
                        <div v-if="station!=null" class="text-left mb-6">
                            <div class="bold">所在地</div>
                            <div>
                                〒{{Station[Station.findIndex(({id}) => id === station)].zip}}&nbsp;{{Station[Station.findIndex(({id}) => id === station)].address}}{{Station[Station.findIndex(({id}) => id === station)].address2}}
                            </div>
                            <div>
                                （<a :href="'https://www.google.com/maps/place/〒'+
                                Station[Station.findIndex(({id}) => id === station)].zip+'+'+Station[Station.findIndex(({id}) => id === station)].address+Station[Station.findIndex(({id}) => id === station)].address2
                                " target="_blank">地図を開く</a>）
                            </div>
                        </div>
                    </v-col>
                </v-row>

                <!-- <h3 class="f-12 mb-5 f-bgray text-left f-10-xxxs">
                    施工ご希望日時をご選択ください
                </h3>
                <v-row>
                    <v-col cols="6">
                        <div @click="calenderOpen()">
                            <v-text-field v-model="day" outlined label="施工のご希望日" class="noevent" :disabled="!station&&Info.type==1" />
                        </div>
                    </v-col>
                    <v-col cols="6">
                        <v-select :items="times" v-model="time" label="ご希望の時間" outlined :disabled="!day" item-disabled="disabled" item-value="time" item-text="text" />
                    </v-col>

                </v-row> -->
                <v-form ref="form">
                    <h3 class="f-12 mb-5 f-bgray text-center ">
                        施工物件・お客様情報の入力
                    </h3>
                    <div class="text-center mb-4">
                        下記項目をご記入ください
                    </div>
                    <v-select v-model="subject" :rules="requredRules" :items="subjects" outlined label="お問合せ内容*" />
                    <v-expand-transition>
                        <v-text-field v-model="etc" v-show="subject=='その他'" label="お問合せ内容をご記入ください" outlined type="text" maxlength="255" />
                    </v-expand-transition>
                    <br>

                    <v-text-field v-model="zip" :rules="zipRules" label="郵便番号" outlined type="text" maxlength="8" class="zip" />
                    <v-text-field v-model="address" label="ご住所*" :rules="requredRules" type="text" outlined maxlength="255" /><br>

                    <v-text-field v-model="name" :rules="requredRules" label="お名前（または法人名）*" outlined type="text" maxlength="255" />

                    <v-text-field v-model="email" :error-messages="emailErrors" outlined label="メールアドレス*" maxlength="255" required @input="$v.email.$touch()" @blur="$v.email.$touch()"></v-text-field>

                    <v-text-field v-model="tel" :rules="requredRules" label="お電話番号*" type="text" outlined maxlength="15" class="tel" />

                    <v-textarea v-model="contact" label="その他" placeholder="ご要望･ご質問等がございましたら記入ください" type="text" outlined maxlength="1000" />
                </v-form>
                <v-btn color="#E64A19" x-large dark block class="f-12 mt-10" @click="submit()">お申込内容の確認</v-btn>
            </v-card>
        </div>
    </v-container>
    <v-dialog v-model="show" persistent width="600">
        <v-card class="pa-6 ">
            <p>
                <span v-if="Info.dayInterval==0"><span class="bold f-11 f-orange">当日</span>予約可能です</span>
                <span v-else class="f-09-xxs">本日から&nbsp;<span class="bold f-11 f-orange">{{Info.dayInterval}}</span>&nbsp;営業日後からご予約可能です</span>
            </p>
            <v-row>
                <v-col cols="6" class="f-08-xxs px-0 mx-0">
                    <div class="text-left noselect relative t-20">
                        <v-icon class="f-08-xxs relative arrow pr-1" @click="$refs.calendar.prev();addM(-1)" :class="{opacity0:toMonth==month,noevent:toMonth==month}">fas fa-chevron-left</v-icon>
                        {{month}}
                        <v-icon class="f-08-xxs relative arrow pl-1" @click="$refs.calendar.next();addM(1)" :class="{opacity0: nextMonth==month,noevent:nextMonth==month}">fas fa-chevron-right</v-icon>

                    </div>
                </v-col>
                <v-col cols="6" class="text-right">
                    <div class="float-right text-center">
                        <div class=" i_block mb-2 float-left">
                            <div class="i_block_inner1 border relative-center"></div>
                            <div class="f-08 f-07-xxs">空き多</div>
                        </div>
                        <div class=" i_block mb-2 float-left">
                            <div class="i_block_inner3 border relative-center"></div>
                            <div class="f-08 f-07-xxs">混雑</div>
                        </div>
                        <div class=" i_block mb-2 float-left">
                            <div class="i_block_inner2 border relative-center"></div>
                            <div class="f-08 f-07-xxs">お休み</div>
                        </div>
                        <div class="clear" />
                    </div>
                    <div class="clear" />
                </v-col>
            </v-row>
            <v-calendar v-if="show4" ref="calendar" color="light-blue lighten-4" v-model="cDay" :events="events" @click:date="reserve" locale="ja-jp" :day-format="(timestamp) => new Date(timestamp.date).getDate()" :month-format="(timestamp) => (new Date(timestamp.date).getMonth() + 1) + ' /'" />
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import moment from "moment"

import d from "@/d";

import Zip from '@/mixins/zip'
import Form from '@/mixins/form'
import Flow from '@/components/flow.vue'

import axios from "axios"
export default {
    mixins: [Zip, Form],
    data() {
        return {
            toMonth: moment().format("yyyy年 M月"),
            month: moment().format("yyyy年 M月"),
            firstMonth: moment().format("yyyy年 M月"),
            nextMonth: moment()
                .add(1, "y")
                .add(-1, "M")
                .format("yyyy年 M月"),
            count: 0,
            show: false,
            show2: false,
            events: [],
            show3: false,
            times: [],
            date: "",
            check: 2,
            show4: true
        }
    },
    components: {
        Flow
    },
    computed: {
        Station() {
            return this.$store.getters['user/station']
        },
        Holiday() {
            return this.$store.getters['user/holiday']
        },
        Obon() {
            return this.$store.getters['user/obon']
        },
    },
    async mounted() {
        if (this.$route.params) {
            for (let key in this.$route.params) {
                this[key] = this.$route.params[key]
            }
            if (this.day) {
                let date = this.day
                this.date = date
                this.day = moment(date).format("yyyy年M月D日")
            }
            if (this.$route.params.address) {
                await d.sleep(100)
                this.address = this.$route.params.address
            }
        }
        if (this.Station.length == 1) {
            this.station = this.Station[0].id
        }

        for (let i = 0; i < this.Obon.length; i++) {
            await this.obonGet(i)
        }
        let events = this.events
        for (let a = 0; a < this.Holiday.length; a++) {
            for (let i = 0; i < 370; i++) {
                let date = moment().add(i, "days").toDate()

                if (
                    (Math.floor((date.getDate() - 1) / 7) + 1 == 1 && this.Holiday[a].one ||
                        Math.floor((date.getDate() - 1) / 7) + 1 == 2 && this.Holiday[a].two ||
                        Math.floor((date.getDate() - 1) / 7) + 1 == 3 && this.Holiday[a].three ||
                        Math.floor((date.getDate() - 1) / 7) + 1 == 4 && this.Holiday[a].four ||
                        Math.floor((date.getDate() - 1) / 7) + 1 == 5 && this.Holiday[a].five
                    ) && moment().add(i, "days").day() == this.Holiday[a].week) {
                    let day = moment().add(i, "days").toDate()
                    events.push({
                        name: "オフ",
                        start: day,
                        end: day,
                        color: "rgba(0,0,0,0)",
                        textColor: "rgba(0,0,0,0)"
                    });
                }
            }
        }

        let suc = 0
        if (this.Info.dayInterval != 0) {
            for (let i = 0; i < 370; i++) {
                if (events.findIndex(({
                        start
                    }) => start == moment().add(i, "days").toDate()) == -1) {
                    let day = moment().add(i, "days").toDate()
                    events.push({
                        name: "インターバル",
                        start: day,
                        end: day,
                        color: "rgba(0,0,0,0)",
                        textColor: "rgba(0,0,0,0)"
                    });

                    suc += 1
                    if (suc == this.Info.dayInterval + 1) {
                        break
                    }
                }
            }
        }

        this.events = events
        if (this.Info.type == 0 || (this.Info.Type == 1 && this.station)) {
            await this.dayGet()
        }
        this.show3 = true
    },
    methods: {
        calenderOpen() {
            if (this.Info.type == 0 || (this.Info.type == 1 && this.station)) {
                this.show = true
            }
        },
        async dayGet() {
            this.show4 = false
            let events = this.events.filter(function (value) {
                return value.name != '混雑';
            })

            const data = {
                station: this.station
            }
            const response = await axios.post("/order/dayGet", data)
            let manys = response.data.manys
            for (let i = 0; i < manys.length; i++) {

                let day = moment(manys[i] + ' 00:00:00').toDate()
                events.push({
                    name: "混雑",
                    start: day,
                    end: day,
                    color: "rgba(0,0,0,0)",
                    textColor: "rgba(0,0,0,0)"
                });
            }
            this.events = events
            this.show4 = true
        },
        back() {
            let params = this.$route.params

            params["zip"] = this.zip
            params["address"] = this.address
            params["company"] = this.company
            params["name"] = this.name
            params["email"] = this.email
            params["tel"] = this.tel
            params["contact"] = this.contact
            params["day"] = this.date ? moment(this.date).format("yyyy-MM-DD") : null
            params["time"] = this.time
            params["station"] = this.station
            params["cDay"] = this.cDay
            params["subject"] = this.subject
            params["etc"] = this.etc

            this.$router.push({
                name: 'estimate',
                params: params,
            })
        },
        obonGet(i) {
            let events = this.events
            let startM = ('00' + this.Obon[i].startM).slice(-2)
            let startD = ('00' + this.Obon[i].startD).slice(-2)
            let endM = ('00' + this.Obon[i].endM).slice(-2)
            let endD = ('00' + this.Obon[i].endD).slice(-2)

            if (moment('2021-' + startM + "-" + startD).isSameOrBefore(moment('2021-' + endM + "-" + endD))) {

                for (let y = 0; y < 2; y++) {
                    let start = moment(moment().add(y, "years").format("yyyy") + "-" + startM + "-" + startD)
                    let end = moment(moment().add(y, "years").format("yyyy") + "-" + endM + "-" + endD)
                    let day = start.toDate()
                    for (i = 0; i < 365; i++) {
                        events.push({
                            name: "オフ",
                            start: day,
                            end: day,
                            color: "rgba(0,0,0,0)",
                            textColor: "rgba(0,0,0,0)"
                        });

                        if (day.getTime() == end.toDate().getTime()) {
                            break;
                        }
                        day = start.add(1, "days").toDate()
                    }
                }
            } else {
                for (let y = 0; y < 2; y++) {
                    let start = moment(moment().add(y, "years").format("yyyy") + "-" + startM + "-" + startD)
                    let end = moment(moment().add(y + 1, "years").format("yyyy") + "-" + endM + "-" + endD)
                    let day = start.toDate()
                    for (i = 0; i < 365; i++) {
                        events.push({
                            name: "オフ",
                            start: day,
                            end: day,
                            color: "rgba(0,0,0,0)",
                            textColor: "rgba(0,0,0,0)"
                        });

                        if (day.getTime() == moment(moment().add(y, "years").format("yyyy") + "-12-31").toDate().getTime()) {
                            break;
                        }
                        day = start.add(1, "days").toDate()
                    }
                    day = moment(moment().add(y + 1, "years").format("yyyy") + "-01-01").toDate()
                    for (i = 0; i < 365; i++) {
                        events.push({
                            name: "オフ",
                            start: day,
                            end: day,
                            color: "rgba(0,0,0,0)",
                            textColor: "rgba(0,0,0,0)"
                        });

                        if (day.getTime() == end.toDate().getTime()) {
                            break;
                        }
                        day = start.add(1, "days").toDate()
                    }
                }
            }
            this.events = events
        },
        reserve(val) {
            this.time = null
            this.date = val.date
            this.day = moment(val.date).format("yyyy年M月D日")
            this.show = false
        },
        getColor() {

            for (
                let i = 0; i <
                document.getElementsByClassName("v-calendar-weekly__day")
                .length; i++
            ) {
                if (document
                    .getElementsByClassName("v-calendar-weekly__day")[i].getElementsByClassName("v-event")[0] &&
                    document
                    .getElementsByClassName("v-calendar-weekly__day")[i].getElementsByClassName("v-event")[0]
                    .innerHTML.indexOf("オフ") != -1
                ) {
                    document.getElementsByClassName("v-calendar-weekly__day")[
                        i
                    ].style.background = "#FBE9E7";

                    document
                        .getElementsByClassName("v-calendar-weekly__day ")[i].classList.add("noevent");
                }

                if (document
                    .getElementsByClassName("v-calendar-weekly__day")[i].getElementsByClassName("v-event")[0] &&
                    document
                    .getElementsByClassName("v-calendar-weekly__day")[i].getElementsByClassName("v-event")[0]
                    .innerHTML.indexOf("インターバル") != -1
                ) {
                    document.getElementsByClassName("v-calendar-weekly__day")[
                        i
                    ].style.background = "#EFEBE9";

                    document
                        .getElementsByClassName("v-calendar-weekly__day ")[i].classList.add("noevent");
                }

                if (
                    document
                    .getElementsByClassName("v-calendar-weekly__day")[i].getElementsByClassName("v-event")[0] &&
                    document
                    .getElementsByClassName("v-calendar-weekly__day")[i].getElementsByClassName("v-event")[0]
                    .innerHTML.indexOf("混雑") != -1
                ) {
                    document.getElementsByClassName("v-calendar-weekly__day")[
                        i
                    ].style.background = "#FFD54F";

                }
            }

            for (
                let i = 0; i <
                document.getElementsByClassName("v-calendar-weekly__day v-past")
                .length; i++
            ) {
                try {

                    document
                        .getElementsByClassName("v-calendar-weekly__day v-past")[i].classList.add("noevent");
                    document.getElementsByClassName(
                        "v-calendar-weekly__day v-past"
                    )[i].style.background = "#EFEBE9";
                } catch (error) {
                    console.log()
                }
            }

        },
        async addM(val) {
            this.count += val
            this.month = moment().add(this.count, "M").format("yyyy年 M月")
            await d.sleep(10)
            this.getColor()
        },

        submit() {
            // if (!this.time || !this.day) {
            //     alert("施工希望日時が選択されていません")
            //     return false
            // }
            this.$refs.form.validate()
            this.$v.$touch()
            if (!this.$v.$invalid && this.$refs.form.validate()) {

                let params = this.$route.params

                params["zip"] = this.zip
                params["address"] = this.address
                params["company"] = this.company
                params["name"] = this.name
                params["email"] = this.email
                params["tel"] = this.tel
                params["contact"] = this.contact
                params["day"] = this.date ? moment(this.date).format("yyyy-MM-DD") : null
                params["time"] = this.time
                params["station"] = this.station
                params["cDay"] = this.cDay
                params["subject"] = this.subject
                params["etc"] = this.etc

                this.$router.push({
                    name: 'comfirm',
                    params: params,
                })
            }
        },
        open() {
            if (this.time && this.day && (this.Info.type == 0 || (this.Info.type == 1 && this.station))) {
                this.show2 = true
                this.check = 3
            }
        },
        stationChange() {
            this.time = null
            this.day = null
            this.date = null
            this.cDay = null
        },
        async timeSet() {
            const data = {
                day: moment(this.cDay).format("yyyy-MM-DD"),
                station: this.station
            }
            const response = await axios.post("/order/timeGet", data)

            if (response.data.times) {
                let times = []
                let min = 30
                let cycle = 2

                if (this.Info.type == 1) {
                    min = 15
                    cycle = 4
                }

                let time = 0
                let num = 0

                for (let i = this.Info.starthh; i <= this.Info.endhh; i++) {
                    for (let a = 0; a < cycle; a++) {
                        if (i == this.Info.starthh && a * min < this.Info.startmm) {
                            continue;
                        }
                        if (i == this.Info.endhh && a * min > this.Info.endmm) {
                            continue;
                        }
                        time = ('00' + i).slice(-2) + ":" + ('00' + a * min).slice(-2)
                        times[num] = {}
                        times[num].time = time
                        if ((response.data.times).indexOf(time) == -1) {
                            times[num].text = time + "　予約〇"
                            times[num].disabled = false
                        } else {

                            times[num].text = time + "　予約×"
                            times[num].disabled = true
                        }

                        num += 1
                    }
                }
                this.times = times
            }
        },
    },
    watch: {
        async show(val) {
            if (val) {
                await d.sleep(10)
                this.getColor()
            }
        },
        time() {
            this.open()
        },
        async day(val) {
            this.open()
            if (val) {
                this.timeSet()
            }
        },

        async station() {
            await this.dayGet()
            this.open()
        },
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/estate.scss";
</style>

<style>
.v-outside {
    pointer-events: none !important;
}

.v-calendar-weekly__day .v-event {
    color: rgba(0, 0, 0, 0) !important;
    height: 0 !important;
}

.v-event-more {
    height: 0 !important;
}
</style>
